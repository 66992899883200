import React, {useContext, useMemo, useState} from "react";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {AddAlgorithm} from "./components/addAlgorithm";
import {AddRules, checkAlgorithmRulesValidity, createNewRule} from "./components/addRules";
import AddAction, {ACTION_TYPES} from "./components/addAction";

import {GlobalContext} from "../context/Provider";
import {createAlgorithms} from "../context/actions/algorithms/algorithms";
import {useLocation} from "react-router-dom";

const ACTIVE_STEP = {
    ADD_ALGORITHM: 0,
    ADD_PERIOD: 1,
    ADD_ACTION: 2
}

const createAddAlgorithmState = (sentState) => ({
    name: sentState?.name || "",
    nameIsTaken: false,
    algorithmType: sentState?.type?.algorithm_type || "",
    algorithmTypeId: sentState?.type?.id || "",
    trafficSourceId: sentState?.traffic_source_id || ""
})

const checkAddAlgorithmState = (addAlgorithmState) => {
    return addAlgorithmState.name !== ""
        && !addAlgorithmState.nameIsTaken
        && addAlgorithmState.algorithmTypeId !== ""
        && addAlgorithmState.trafficSourceId !== ""
}

const areAlgoPeriodsValid = (algorithmPeriods, algorithmType) => {
    // if bidding optimization no algo level periods checked
    if (algorithmType === "BIDDING_OPTIMIZATION") {
        return true
    }
    // if not bidding optimization at least one period required
    return algorithmPeriods && algorithmPeriods.length > 0;
}

export function CreateAlgorithm() {
    const sentState = useLocation()?.state;

    const user = JSON.parse(localStorage.getItem("user"));

    // currently visible step of the create-algorithm process
    const [activeStep, setActiveStep] = useState(ACTIVE_STEP.ADD_ALGORITHM);

    // algorithm name, type and type id
    const [addAlgorithmState, setAddAlgorithmState] = useState(createAddAlgorithmState(sentState))

    const [skipped, setSkipped] = useState(new Set());

    // which periods should the new algorithm run with (to be used for algorithm
    // types that define the period on an algorithm level, not rule level)
    const [algorithmPeriods, setAlgorithmPeriods] = useState(
        sentState?.period || []
    );

    const [algorithmRules, setAlgorithmRules] = useState(
        sentState?.rules || [createNewRule()]
    )

    const rulesAreValid = useMemo(
        /*fn*/() => checkAlgorithmRulesValidity(algorithmRules, addAlgorithmState.algorithmType),
        /*deps*/[algorithmRules, addAlgorithmState.algorithmType]);
    const algorithmPeriodIsValid = useMemo(
        /*fn*/() => areAlgoPeriodsValid(algorithmPeriods, addAlgorithmState.algorithmType),
        /*deps*/[algorithmPeriods, addAlgorithmState.algorithmType]
    )

    const [algorithmAction, setAlgorithmAction] = useState(sentState?.action || {
        type: ACTION_TYPES.INCREASE_BID,
        value: 0
    });

    let steps = useMemo(
        /*fn*/ () => addAlgorithmState.algorithmType === "BIDDING_OPTIMIZATION"
            ? ["Add Algorithm", "Define Rules", "Define Action"]
            : ["Add Algorithm", "Define Rules"],
        /*deps*/[addAlgorithmState.algorithmType]);


    const actionsFinishBtn = useMemo(
        () => {
            switch (algorithmAction.type) {
                case ACTION_TYPES.SET_BID:
                    return !(algorithmAction.value <= 0 || algorithmAction.period_in_days <= 0 || algorithmAction.epc_or_epm === "");
                case ACTION_TYPES.INCREASE_BID:
                    return algorithmAction.value > 0;
                case ACTION_TYPES.DECREASE_BID:
                    return algorithmAction.value > 0;
                default:
                    return true
            }
        }
        , [algorithmAction])


    const {
        algorithmsDispatch,
        algorithmsState
    } = useContext(GlobalContext);

    const {
        addAlgorithms: {error},
    } = algorithmsState;

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        const isLastStep = activeStep === steps.length - 1;
        if (!isLastStep) {
            return;
        }
        // ON LAST STEP
        let data = {
            name: addAlgorithmState.name,
            type_id: addAlgorithmState.algorithmTypeId,
            period: algorithmPeriods,
            traffic_source_id: addAlgorithmState.trafficSourceId,
            created_by: user.id,
            is_default: false,
            rules: algorithmRules,
        };
        if (addAlgorithmState.algorithmType === "BIDDING_OPTIMIZATION") {
            data.action = algorithmAction
            // erase any periods that might have been left in the state while changing algo types
            data.period = []
        }

        createAlgorithms(data)(algorithmsDispatch);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setAddAlgorithmState(createAddAlgorithmState({}))
        setAlgorithmRules([createNewRule()]);
        setAlgorithmPeriods([]);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <AddAlgorithm
                        addAlgorithmState={addAlgorithmState}
                        onAddAlgorithmStateChange={setAddAlgorithmState}
                    />
                );
            case 1:
                return (
                    <AddRules
                        selectedType={addAlgorithmState.algorithmType}
                        selectedAlgorithmPeriods={algorithmPeriods}
                        setSelectedAlgorithmPeriods={setAlgorithmPeriods}
                        algorithmRules={algorithmRules}
                        setAlgorithmRules={setAlgorithmRules}
                    />
                );
            case 2:
                return (
                    <AddAction
                        actionDefinition={algorithmAction}
                        onActionDefinitionChange={setAlgorithmAction}
                    />
                );
            default:
                return "Unknown step";
        }
    };
    const isAlgorithmStateValid = useMemo(() => checkAddAlgorithmState(addAlgorithmState), [addAlgorithmState]);

    return (
        <Box sx={{width: "100%"}}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <>
                    {error ?
                        (<Typography sx={{mt: 2, mb: 1}}>Error creating algorithm: <b>{error.detail}</b></Typography>)
                        :(<Typography sx={{mt: 2, mb: 1}}>Successfully created</Typography>)
                    }
                    <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
                        <Box sx={{flex: "1 1 auto"}}/>
                        <Button onClick={handleReset}>Create New</Button>
                    </Box>
                </>
            ) : (
                <>
                    {getStepContent(activeStep)}

                    <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
                        <Button
                            color="inherit"
                            disabled={activeStep === ACTIVE_STEP.ADD_ALGORITHM}
                            onClick={handleBack}
                            sx={{mr: 1}}
                        >
                            Back
                        </Button>
                        <Box sx={{flex: "1 1 auto"}}/>
                        {activeStep !== steps.length - 1 && activeStep !== ACTIVE_STEP.ADD_PERIOD && (
                            <Button
                                disabled={!isAlgorithmStateValid}
                                onClick={handleNext}
                            >
                                Next
                            </Button>
                        )}
                        {activeStep === ACTIVE_STEP.ADD_PERIOD && addAlgorithmState.algorithmType === "BIDDING_OPTIMIZATION" && (
                            <Button
                                disabled={
                                    !algorithmPeriodIsValid ||
                                    !isAlgorithmStateValid ||
                                    !rulesAreValid
                                }
                                onClick={handleNext}
                            >
                                Next
                            </Button>
                        )}
                        {activeStep === ACTIVE_STEP.ADD_PERIOD &&
                            addAlgorithmState.algorithmType !== "BIDDING_OPTIMIZATION" && (
                                <Button
                                    disabled={
                                        !algorithmPeriodIsValid ||
                                        !isAlgorithmStateValid ||
                                        !rulesAreValid
                                    }
                                    onClick={handleNext}
                                >
                                    Finish
                                </Button>
                            )}
                        {activeStep === ACTIVE_STEP.ADD_ACTION && addAlgorithmState.algorithmType === "BIDDING_OPTIMIZATION" && (
                            <Button
                                disabled={
                                    !algorithmPeriodIsValid ||
                                    !isAlgorithmStateValid ||
                                    !rulesAreValid ||
                                    activeStep !== ACTIVE_STEP.ADD_ACTION ||
                                    !actionsFinishBtn
                                }
                                onClick={handleNext}
                            >
                                Finish
                            </Button>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
}
