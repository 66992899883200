import React, {useContext, useEffect, useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import {
    deleteAlgorithms,
    getAlgorithms,
    getAlgorithmsTypes,
    resetMessageAlgorithmDelete,
    turnOnOffAlgorithms,
} from "../context/actions/algorithms/algorithms";
import {GlobalContext} from "../context/Provider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {AlertDialog} from "../components/dialog";
import Grid from "@mui/material/Grid";
import {getTrafficSource} from "../context/actions/traffic/trafficSource";
import TransitionAlerts from "../components/alert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {useNavigate} from "react-router-dom";
import {ACTION_TYPES} from "./components/addAction";

export function ListAlgorithms() {
    const {
        algorithmsState,
        algorithmsDispatch,
        tsAccountsDispatch,
        tsAccountsState,
    } = useContext(GlobalContext);

    const navigate = useNavigate();

    const {
        algorithmsList: {data, message, error},
        algorithmsTypes,
    } = algorithmsState;
    const [open, setOpen] = useState(false);
    const [algorithmId, setAlgorithmId] = useState("");
    const [algorithmName, setAlgorithmName] = useState("");
    const {trafficSourceList} = tsAccountsState;
    const [trafficSource, setTrafficSource] = useState("");

    const [showErrorMsg, setShowErrorMsg] = useState(false);

    const trafficSourceDict = trafficSourceList.data.reduce(function (map, obj) {
        map[obj.id] = obj.name;
        return map;
    }, {});

    useEffect(() => {
        getAlgorithmsTypes()(algorithmsDispatch);
    }, [algorithmsDispatch]);

    useEffect(() => {
        getAlgorithms(trafficSource)(algorithmsDispatch);
    }, [algorithmsDispatch, trafficSource]);

    useEffect(() => {
        getTrafficSource()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    useEffect(() => {
        setShowErrorMsg(error);
        if (showErrorMsg) {
            window.scrollTo({top: 0, behavior: "smooth"});
        }
    }, [error, showErrorMsg]);

    const handleTurnOnOffAlgorithm = (event, item, index) => {
        turnOnOffAlgorithms(
            item.id,
            event.target.checked,
            trafficSource
        )(algorithmsDispatch);
    };

    const handleDeleteAlgorithm = (id, name) => {
        setOpen(true);
        setAlgorithmId(id);
        setAlgorithmName(name);
    };

    const handleCloneAlgorithm = (algorithm) => {
        // db returns NULL for multiplier_a in some parts
        const rules = algorithm.rules.map(rule => {
            const newRule = {
                parts: rule.parts,
                period: rule.period,
            }
            newRule.parts = newRule.parts.map(part => {
                if (part.multiplier_a === undefined || part.multiplier_a === null) {
                    return {
                        var_a: part.var_a,
                        var_b: part.var_b,
                        operator: part.operator,
                        multiplier_b: part.multiplier_b,
                        multiplier_a: 1.0,
                    }
                }
                return part;
            })
            return newRule;
        });
        navigate("/create_algorithm", {
            state: {
                name: algorithm.name + " COPY",
                period: algorithm.period,
                traffic_source_id: algorithm.traffic_source_id,
                type: algorithm.type,
                rules: rules,
                action: algorithm.action,
            }
        })
    }

    const handleClose = () => {
        setOpen(false);
        setAlgorithmId("");
        setAlgorithmName("");
    };

    const handleChangeTs = (event) => {
        setTrafficSource(event.target.value);
    };
    const replaceString = (name) => {
        const replace = name.replaceAll("_", " ");
        return replace.toLowerCase();
    };

    const handleDelete = () => {
        setOpen(false);
        deleteAlgorithms(algorithmId)(algorithmsDispatch);
        setAlgorithmId("");
    };

    const handleAlertDialog = () => {
        setShowErrorMsg((prevCheck) => !prevCheck);
        resetMessageAlgorithmDelete()(algorithmsDispatch);
    };

    const convertActionToString = (action) => {
        switch (action.type) {
            case ACTION_TYPES.SET_BID:
                return (
                    <span style={{
                        marginLeft: "20px",
                        color: "#666"
                    }}>Set bid to: <b>{action.value}%</b> of <b>{action.epc_or_epm}</b> of last <b>{action.period_in_days}</b> days</span>);
            case ACTION_TYPES.INCREASE_BID:
                return (
                    <span style={{marginLeft: "20px", color: "#666"}}>Increase bid by: <b>{action.value}</b>%</span>);
            case ACTION_TYPES.DECREASE_BID:
                return (
                    <span style={{marginLeft: "20px", color: "#666"}}>Decrease bid by: <b>{action.value}</b>%</span>);
            default:
                return "";
        }

    }

    return (
        <>
            <Grid item xs={4} sx={{display: "flex", mb: 3}}>
                <FormControl fullWidth>
                    <InputLabel id="ts-account-label">Traffic Source</InputLabel>
                    <Select
                        required
                        labelId="ts-account-label"
                        id="ts-account-label-select"
                        value={trafficSource}
                        label="Traffic Source"
                        onChange={handleChangeTs}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {(trafficSourceList.data || []).map((item, i) => {
                            return (
                                <MenuItem key={i} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} justifyContent="center" sx={{display: "flex"}}>
                {showErrorMsg && (
                    <TransitionAlerts
                        title={message}
                        handleAlertDialog={handleAlertDialog}
                    />
                )}
            </Grid>
            {algorithmsTypes &&
                algorithmsTypes.data.map((type) => (
                    <Accordion key={type.id}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div>{type.algorithm_type}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {data
                                .filter(
                                    (algorithm) => algorithm.type.algorithm_type === type.algorithm_type
                                )
                                .map((algorithm, index) => (
                                    <div key={algorithm.id}>
                                        <Card variant="outlined" sx={{mb: 5}}>
                                            <CardContent>
                                                <Box
                                                    variant="h5"
                                                    component="div"
                                                    color="text.secondary"
                                                >
                                                    ID: {algorithm.id} |
                                                    {algorithm.ts_account_id ? (
                                                        <span style={{marginLeft:"5px"}}>
                                                            <ShieldTwoToneIcon sx/> Private
                                                        </span>
                                                    ) : (<span style={{marginLeft:"5px"}}>
                                                            <PublicTwoToneIcon/> Public
                                                    </span>)}
                                                </Box>
                                                <Box
                                                    variant="h5"
                                                    component="div"
                                                    color="text.secondary"
                                                >
                                                    Name: {algorithm.name}
                                                    <Switch
                                                        checked={algorithm.turned_on}
                                                        onClick={(event) => {
                                                            handleTurnOnOffAlgorithm(event, algorithm, index);
                                                        }}
                                                        inputProps={{"aria-label": "uncontrolled"}}
                                                    />
                                                </Box>
                                                <Box
                                                    variant="h5"
                                                    component="div"
                                                    color="text.secondary"
                                                >
                                                    Traffic Source:{" "}
                                                    {trafficSourceDict[algorithm.traffic_source_id]}<br/>
                                                </Box>

                                                <Box variant="h5" color="text.secondary">
                                                    Type: {algorithm.type.algorithm_type}
                                                </Box>
                                                <Box variant="h5" color="text.secondary">
                                                    Period:{" " + algorithm.period.join(", ")}
                                                    {algorithm.period.length > 0 ? " days" : "None"}
                                                </Box>
                                                <Box
                                                    variant="h5"
                                                    component="div"
                                                    color="text.secondary"
                                                >
                                                    Operations:
                                                    <div style={{marginLeft: "10px"}}>
                                                        <div
                                                            onClick={() => handleDeleteAlgorithm(algorithm.id, algorithm.name)}>
                                                            <DeleteIcon/>
                                                            Delete
                                                        </div>
                                                        <div onClick={() => handleCloneAlgorithm(algorithm)}>
                                                            <FileCopyIcon/>
                                                            Clone
                                                        </div>
                                                    </div>


                                                </Box>
                                                {algorithm.rules && algorithm.rules.length > 0 ?
                                                    (<Box variant="body2" key={"rulebox-" + algorithm.id}>
                                                        Rules: <br/>
                                                        {algorithm.rules.map((rule, index) => (
                                                            <table key={"rule-table" + rule.id} width={"100%"}
                                                                   style={{marginLeft: "20px", color: "#666"}}>
                                                                <thead>
                                                                <tr>
                                                                    <td>Rule {index + 1}:</td>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td style={{
                                                                        textAlign: "left",
                                                                        display: "flex"
                                                                    }}>{rule.parts.map((item, i) => (
                                                                        <Box
                                                                            component="div"
                                                                            sx={{display: "flex"}}
                                                                            key={item.id}
                                                                        >
                                                                            <Box
                                                                                component="div"
                                                                                sx={{display: "inline", mr: 1.5}}
                                                                            >
                                                                                <Box
                                                                                    component="div"
                                                                                    sx={{display: "inline", mr: 1.5}}
                                                                                >
                                                                                    {i ? "&" : ""}
                                                                                </Box>
                                                                                <Box
                                                                                    component="div"
                                                                                    sx={{display: "inline", mr: 1.5}}
                                                                                >
                                                                                    {replaceString(item.var_a)}
                                                                                    {item.multiplier_a === null ||
                                                                                    item.multiplier_a === 1
                                                                                        ? ""
                                                                                        : " * " + item.multiplier_a}
                                                                                </Box>
                                                                                <Box
                                                                                    component="div"
                                                                                    sx={{display: "inline", mr: 1.5}}
                                                                                >
                                                                                    {item.operator === "EQUAL" && "="}
                                                                                    {item.operator === "GREATER_THAN" && ">"}
                                                                                    {item.operator ===
                                                                                        "GREATER_THAN_OR_EQUAL" && ">="}
                                                                                    {item.operator === "LOWER_THAN" && "<"}
                                                                                    {item.operator ===
                                                                                        "LOWER_THAN_OR_EQUAL" && "<="}
                                                                                </Box>
                                                                                <Box
                                                                                    component="div"
                                                                                    sx={{display: "inline", mr: 0.5}}
                                                                                >
                                                                                    {item.multiplier_b}
                                                                                    {item.var_b === "NONE" ? "" : "*"}
                                                                                </Box>
                                                                                <Box
                                                                                    component="div"
                                                                                    sx={{display: "inline", mr: 1.5}}
                                                                                >
                                                                                    {item.var_b === "NONE"
                                                                                        ? ""
                                                                                        : replaceString(item.var_b)}
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    ))}</td>
                                                                </tr>
                                                                {type.algorithm_type === "BIDDING_OPTIMIZATION" ? (
                                                                    <tr>
                                                                        <td style={{textAlign: "left"}}>{"Rule Period: " + rule.period.join(", ") + " days"}</td>
                                                                    </tr>
                                                                ) : (<tr>
                                                                    <td></td>
                                                                </tr>)}
                                                                </tbody>
                                                            </table>
                                                        ))}
                                                        {algorithm.action ? (
                                                            <div>
                                                                Action:<br/>
                                                                {convertActionToString(algorithm.action)}
                                                            </div>) : ""}

                                                    </Box>) : ""
                                                }

                                            </CardContent>
                                        </Card>
                                    </div>
                                ))}
                        </AccordionDetails>
                    </Accordion>
                ))}

            <AlertDialog
                open={open}
                handleClose={handleClose}
                name={algorithmName}
                handleDelete={handleDelete}
            />
        </>
    );
}
